.landing-footer {
	display: flex;
	margin-top: 70px;
	align-items: center;
	background-color: #fff8d3;
	padding: 20px;
	border-radius: 10px;
	margin: 25px 5%;

	.img-container {
		width: 30%;
		height: 100%;
		img {
			display: block;
			margin: auto;
			width: 200px;
			height: auto;
		}
	}

	ul {
		list-style: none;
		width: 33%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		justify-content: center;
		li {
			text-align: center;
			a {
				color: black;
				text-decoration: none;
				gap: 20px;
			}
		}
	}

	span {
		width: 33%;
		text-align: center;
		a {
			color: black;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 30px;
		.img-container,
		ul,
		span {
			width: 100%;
		}
	}

	.footer-text {
		text-align: center;
		margin: auto;
	}
}
