.landing-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	position: relative;
	margin: 25px 5% -25px;
	.link-logo {
		height: 100%;
		display: flex;
		align-items: center;

		img {
			height: 80%;
			width: auto;
		}
	}

	nav {
		display: flex;
		align-items: center;
		gap: 30px;
		ul {
			list-style: none;
			display: flex;
			gap: 30px;
			li {
				a {
					text-decoration: none;
					color: black;

					&:hover {
						background-color: #fffbe7;
						border-radius: 10px;
					}
				}
			}
		}

		.login-btn-div {
			background-color: #afffd9;
			height: 50%;
			padding: 5px 30px;
			display: flex;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;
			transition: 0.3s ease-in-out;
			color: black;
			text-decoration: none;
			border: 1px solid transparent;

			&:hover {
				border: 1px solid #58d39a;
			}
		}
	}

	@media (max-width: 1000px) {
		nav {
			position: fixed;
			top: 0;
			right: -200px;
			height: 100vh;
			width: 200px;
			border-left: 1px solid lightgrey;
			background-color: white;
			flex-direction: column;
			padding-top: 40px;
			z-index: 10;
			align-items: flex-start;
			padding-left: 30px;
			transition: 0.2s ease-in-out;

			ul {
				flex-direction: column;

				.sidebar-logo {
					width: 30%;
					height: fit-content;
				}
			}
			.login-btn-div {
				height: 30px;
				margin-top: 50px;
			}
		}

		.show-menu {
			right: 0px;
		}
	}

	@media (min-width: 1600px) {
		margin: 25px 10%;
	}
}

.stopper {
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
}
